<template>
  <div class="full-page">
    <page-load-more @reachBottom="getAppointmentRecord()" :loading="listLoading" :loaded="listLoaded">
      <div v-if="!appointmentRecordList.length" class="page-empty">
        <img src="@/assets/empty.png">
        <p>暂无数据</p>
      </div>
      <div v-else class="record-list">
        <div class="record-item" v-for="( item, index ) in appointmentRecordList" :key="index">
          <div class="header">
            <div class="appointment-date">
              <span>{{item.reservationTime}} </span>
              <span>{{ periodStatus[item.period] }}</span>
              <span>{{'（' + item.department.name + '）'}}</span>
            </div>
            <div class="appointment-status">
              <span v-if="item.status === 'UNPAY'">未支付</span>
              <span v-if="item.status === 'PAY'" style="color: #00B6A4">已支付</span>
              <span v-if="item.status === 'CANCEL'" style="color: orange">已取消</span>
            </div>
          </div>
          <div class="content">
            <div class="doctor-infor">
              <div class="left">
                <span>{{item.doctors.name}}</span>
                <div class="doctor-tag"><span>{{item.doctors.positionName}}</span></div>
              </div>
              <div class="right"><span>¥{{item.price | formatPrice}}</span></div>
            </div>
            <div class="appointment-infor">
              <div class="title">预约信息</div>
              <div class="appointment-person">
                <div>
                  <span>{{item.name}}</span>
                  <div class="person-tag"><span>{{item.sex}}</span></div>
                  <div class="person-tag"><span>{{item.age}}岁</span></div>
                </div>
                <div class="appointmentId">{{item.mobile}}</div>
              </div>
              <div class="appointment-numbers" v-if="item.status === 'PAY'">
                <span>预约编号：{{item.numbering}}</span><span style="font-size: .28rem"></span>
              </div>
            </div>
            <div class="topay" v-if="item.status === 'UNPAY'">
              <van-button color="#00B6A4" style="height: .58rem" @click="toPay(item)">去支付</van-button>
            </div>
          </div>
        </div>
      </div>
    </page-load-more>
  </div>
</template>

<script>
  import PageLoadMore from '@/components/page-load-more'
  export default {
    components: {
      PageLoadMore
    },

    data() {
      return {
        appointmentRecordList: [],
        payStatus: {
          CANCEL: '已取消',
          UNPAY: '待支付',
          PAY: '已支付',
        },

        listLoading: false,
        listLoaded: true,

        query: {
          pageNo: 0,
          pageSize: 10,
        },

        periodStatus: {
          MORNING: '上午',
          AFTERNOON: '下午',
          NIGHT: '晚上',
        }
      }
    },

    created() {
      this.getAppointmentRecord()
    },

    methods: {
      getAppointmentRecord () {
        ++this.query.pageNo
        this.$request ({
          url: '/reservation/list/',
          data: this.query
        }).then( res => {
          res.result.rows.map( item => {
            this.appointmentRecordList.push(item)
          })
          this.listLoaded = res.result.total <= this.appointmentRecordList.length
          this.listLoading = false
        })
      },

      toPay (item) {
        this.$router.push({path: '/pay-page', name: 'pay-page', params: {orderIdOrSN: item.id}})
      }
    },
  }
</script>

<style lang="scss" scoped>
  .full-page {
    .record-list {
      padding: .24rem;
      .record-item {
        background: #fff;
        border-radius: .16rem;
        margin-bottom: .24rem;
        .header {
          font-size: .3rem;
          font-weight: bold;
          padding: .24rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #EEEEEE;
          .appointment-status {
            color: #E02021;
            font-size: .26rem;
          }
        }
        .content {
          padding: .3rem .24rem;
          .doctor-infor {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left {
              font-size: .32rem;
              .doctor-tag {
                display: inline-block;
                padding: .04rem;
                border-radius: .06rem;
                color: #00B6A4;
                border: 1px solid #00B6A4;
                font-size: .22rem;
                margin-left: .28rem;
              }
            }
            .right {
              font-size: .36rem;
              color: #E02021;
            }
          }

          .appointment-infor {
            padding: .24rem;
            background: #F5F5F5;
            margin-top: .26rem;
            border-radius: .08rem;
            .title {
              font-size: .26rem;
              color: #999999;
            }

            .appointment-person {
              font-size: .32rem;
              color: #666;
              margin-top: .16rem;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .person-tag {
                display: inline-block;
                padding: .04rem;
                border-radius: .06rem;
                color: #00B6A4;
                border: 1px solid #00B6A4;
                font-size: .22rem;
                margin-left: .28rem;
              }
              .appointmentId {
                font-size: .3rem;
              }
            }

            .appointment-numbers {
              margin-top: .2rem;
              color: #999;
              font-size: .26rem;
            }
          }

          .topay {
            text-align: right;
            padding-top: .24rem;
            border-radius: .04rem;
            overflow: hidden;
          }
        }

      }
    }
  }
</style>